.mobile-menu-drawer-wrapper{
    z-index: 200;
    position: fixed;
    top:0;
    right: 0;
    display: none;
    flex-direction: column;
    width: 100%;
    max-width: 0;
    transition: all .3s ease-in-out;
    background-color: white;
    height: 100vh;
    overflow: hidden;

    @media (max-width: 1200px) {
        display: flex;
      }
} 

.mobile-menu-drawer-header-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;

    div{
        font-size: 20px;
        font-weight: 700;
    }
    
    img{
        cursor: pointer;
        width: 44px;
        height: 44px;
    }
}

.mobile-menu-drawer-content-col{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 32px;
    padding: 8px 16px 100px 16px;
    gap: 36px;

    a{
        height: 36px;
        display: inline;
        max-width: 100%;
        text-decoration: none;
        overflow: hidden;
    }

    div{
        color: #333;
        font-weight: 700;
        transition: all .1s ease-out;
        line-height: 100%;
    }

    div:hover{
        color: #666;
    }
}