.openAPI-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.openAPI-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1120px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.openAPI-menu-container {
  flex: 3 1 0;
  border-right: 1px solid #ebebf2;
  padding: 136px 0;

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.mobile-menu-fold {
  .openAPI-mobile-menu-spread {
    max-height: 0 !important;
    padding: 0 !important;
    border: none !important;
  }

  .openAPI-mobile-menu-arrow {
    width: 20px;
    height: 20px;
    transition: all 0.2s ease-out;
    transform: rotate(0deg) !important;
  }
}

.openAPI-mobile-menu-container {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  position: fixed;
  display: none;
  flex-direction: column;
  margin-top: 64px;
  width: 100%;

  .openAPI-mobile-menu {
    position: fixed;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    padding: 12px 28px 12px 16px;
    border-top: 1px solid #e9e9f0;
    background-color: white;
    font-size: 16px;
    color: #222;
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: #f7f7fc;
    }

    .openAPI-mobile-menu-arrow {
      width: 20px;
      height: 20px;
      transition: all 0.2s ease-out;
      transform: rotate(-180deg);
    }
  }

  .openAPI-mobile-menu-spread::-webkit-scrollbar {
    display: none;
  }

  .openAPI-mobile-menu-spread {
    top: 56px;
    position: absolute;
    width: 100%;
    max-height: 50vh;
    overflow: scroll;
    background-color: white;
    border: 1px solid #e9e9f0;
    padding-bottom: 24px;
    transition: all 0.3s ease-out;

    .openAPI-mobile-menu-spread-label {
      font-size: 14px;
      font-weight: 700;
      color: #444;
      margin-bottom: 8px;
      margin-top: 24px;
      padding: 0 16px;
    }

    .openAPI-mobile-menu-spread-menu {
      cursor: pointer;
      font-size: 16px;
      color: #222;
      padding: 14px 16px;
      transition: background-color 0.1s ease-out;

      &:hover {
        background-color: #f7f7fc;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    display: flex;
  }
}

.openAPI-content-container {
  display: flex;
  flex-direction: column;
  flex: 8 1 0;
  padding: 120px 0 120px 48px;
  gap: 24px;

  @media screen and (max-width: 1200px) {
    padding: 164px 0px;
  }
}

.openAPI-menuset {
  .openAPI-label {
    margin-left: 52px;
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 14px;
    color: #444;
  }
  .openAPI-menu {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    height: 48px;
    font-size: 14px;
    color: #222;
    gap: 20px;
    transition: background-color 0.3 ease-out;

    &:hover {
      background-color: #e9e9f0;

      img {
        margin-bottom: 4px;
      }
    }

    img {
      width: 16px;
      height: 16px;
      transition: transform 0.3s ease-out, margin 0.2s ease-out;
    }

    & + .openAPI-menu-depth {
      opacity: 0;
      transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
      max-height: 0 !important;
    }
  }
  .openAPI-menu-spread {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    height: 48px;
    font-size: 14px;
    color: #222;
    gap: 20px;
    transition: background-color 0.3 ease-out;

    &:hover {
      background-color: #e9e9f0;

      img {
        margin-bottom: 4px;
      }
    }

    img {
      width: 16px;
      height: 16px;
      transform: rotate(-180deg);
      transition: transform 0.3s ease-out, margin 0.2s ease-out;
    }

    & + .openAPI-menu-depth {
      opacity: 1;
      transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
      transition: max-height 0.3s ease-out;
    }
  }
  .openAPI-menu-depth {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .openAPI-menu-small {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 52px;
      padding-right: 24px;
      height: 40px;
      font-size: 14px;
      color: #444;

      &:hover {
        background-color: #e9e9f0;
      }

      .openAPI-menu-small-type-get {
        font-family: 'Outfit';
        font-size: 10px;
        font-weight: 700;
        padding: 2px 8px;
        border-radius: 16px;
        color: #3868df;
        border: 1px solid #3868df;
        // color: white;
        // background-color: #3868df;
      }

      .openAPI-menu-small-type-post {
        font-family: 'Outfit';
        font-size: 10px;
        font-weight: 700;
        padding: 2px 8px;
        border-radius: 16px;
        color: #28b028;
        border: 1px solid #28b028;
        // color: white;
        // background-color: #28c028;
      }

      .openAPI-menu-small-type-delete {
        font-family: 'Outfit';
        font-size: 10px;
        font-weight: 700;
        padding: 2px 8px;
        border-radius: 16px;
        color: #df4848;
        border: 1px solid #df4848;
        // color: white;
        // background-color: #df4848;
      }

      .openAPI-menu-small-type-put {
        font-family: 'Outfit';
        font-size: 10px;
        font-weight: 700;
        padding: 2px 8px;
        border-radius: 16px;
        color: #7f48df;
        border: 1px solid #7f48df;
        // color: white;
        // background-color: #7f48df;
      }
    }
  }
}

.openAPI-content-container {
  h2 {
    font-family: 'Outfit';

    @media screen and (max-width: 1200px) {
      padding: 0 16px;
    }
  }

  .openAPI-content-script {
    font-size: 14px;
    line-height: 200%;
    color: #444;

    @media screen and (max-width: 1200px) {
      padding: 0 16px;
    }
  }

  .openAPI-content-type-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Outfit';
    font-size: 14px;
    color: #222;
    gap: 12px;
    margin: 36px 0;

    @media screen and (max-width: 1200px) {
      padding: 0 16px;
    }

    .openAPI-content-type-get {
      font-family: 'Outfit';
      font-size: 12px;
      font-weight: 700;
      padding: 4px 12px;
      border-radius: 16px;
      color: white;
      background-color: #3868df;
      width: fit-content;
    }
  }

  .openAPI-table-container-request-params,
  .openAPI-table-container-response,
  .openAPI-table-container-query-params,
  .openAPI-table-container-header,
  .openAPI-table-container-responses-code {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-bottom: 40px;

    .openAPI-table-title {
      font-family: 'Outfit';
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;

      @media screen and (max-width: 1200px) {
        padding: 0 16px;
      }
    }

    .openAPI-table::-webkit-scrollbar {
      display: none;
    }

    .openAPI-table {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      overflow: scroll;

      @media screen and (max-width: 1200px) {
        padding: 0 36px 0 16px;
      }

      .openAPI-table-head,
      .openAPI-table-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #444;
        min-width: 640px;
        width: 100%;

        .table-cell {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          padding: 6px 20px;
          line-height: 30px;
          height: 100%;

          .openAPI-table-require {
            color: #df4848;
            font-size: 11px;
          }

          .code-sign-success {
            background-color: #28e428;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            margin-top: 2px;
          }

          .code-sign-error {
            background-color: #ff2828;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            margin-top: 2px;
          }
        }

        .left {
          justify-content: flex-start;
        }

        .center {
          justify-content: center;
        }

        .right {
          justify-content: flex-end;
        }

        .fg1 {
          flex: 1 1 0;
        }

        .fg2 {
          flex: 2 1 0;
        }

        .fg3 {
          flex: 3 1 0;
        }

        .fg4 {
          flex: 4 1 0;
        }

        .fg5 {
          flex: 5 1 0;
        }
      }

      .openAPI-table-head {
        background-color: #e9e9f0;
        font-weight: bold;
        border-top: 1px solid #dfdfe8;
        border-bottom: 1px solid #dfdfe8;

        .table-cell {
          &:nth-child(1),
          &:nth-child(2) {
            border-right: 1px solid #dfdfe8;
          }
        }
      }

      .openAPI-table-body {
        border-bottom: 1px solid #e8e8f0;

        .table-cell {
          &:nth-child(1),
          &:nth-child(2) {
            border-right: 1px solid #e8e8e8;
          }
        }
      }
    }
  }
}
