.download-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f2f2f7;
}

.download-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  padding: 184px 16px;
  gap: 100px;

  @media screen and (max-width: 1200px) {
    padding: 144px 16px;
    gap: 64px;
  }
}

.download-title-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 100%;
}

.download-title {
  font-family: 'Outfit';
  font-weight: 600;
}

.download-contentset-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.download-contentset {
  display: none;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  transition: all 0.33s ease-out;
  width: 100%;
}

.download-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 64px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 100px;
    width: 100%;
  }
}

.download-companyname {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #222;
  gap: 24px;
  padding: 48px 0 72px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  img {
    height: 36px;
    // width: fit-content;
  }
}

.download-contentset-wrapper .selectedTab {
  display: flex;
  opacity: 1;
}

.download-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
}

.download-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 36px;
  background-color: #f2f2f7;
  box-shadow: 24px 24px 48px -24px rgba(0, 0, 0, 0.15),
    -24px -24px 48px -24px rgba(255, 255, 255, 1);

  @media screen and (max-width: 1200px) {
    width: 144px;
    height: 144px;
  }
}

.download-icon {
  width: 100px;
  height: 100px;

  @media screen and (max-width: 1200px) {
    width: 72px;
    height: 72px;
  }
}

.download-content-name {
  font-family: 'Outfit';
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.download-buttonset {
  display: flex;
  flex-direction: column;
  text-align: center;

  span {
    cursor: pointer;
    font-size: 16px;
    color: #0075ff;
    margin: 8px 0;
  }

  span:hover {
    text-decoration: underline;
  }
}

.download-img {
  width: 100%;

  @media screen and (max-width: 1200px) {
    margin-top: 36px;
  }
}

// tab

.download-tab-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  width: 100%;
}

.download-tab-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 56px;
  border-radius: 12px;
  transition: all 0.15s ease-out;

  @media screen and (max-width: 640px) {
    flex: 1 1 0;
    width: auto;
  }

  font-size: 18px;
}

.download-tab-wrapper .inactive {
  background-color: #e4e4ed;
  color: #a4a4ba;

  &:hover {
    background-color: #e9e9f0;
    color: #bebecf;
  }
}

.download-tab-wrapper .active {
  background-color: #0ece8e;
  color: white;
  font-weight: 700;
}

.clicktocall-wrapper {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;

  .label {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 28px;
  }

  .row {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  input {
    @media (max-width: 480px) {
      width: 280px;
      margin-right: 0px;
      margin-bottom: 12px;
    }

    border: solid 1px #eee;
    padding: 14px;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      color: #bbb;
    }

    margin-right: 12px;
  }

  button {
    @media (max-width: 480px) {
      width: 308px;
    }

    cursor: pointer;

    background-color: #0ece8e;
    color: white;
    border: 0px;
    font-weight: bold;
    padding: 0px 16px;
    height: 43.5px;
  }
}