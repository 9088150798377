.footer-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 72px 16px;
    background-color: #E9E9F0;
}

.footer-container{
    display: flex;
    max-width: 1120px;
    width: 100%;
    flex-direction: column;
    gap: 12px;

    @media screen and (max-width: 640px){
        gap: 12px;
    }
}

.footer-row{
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media screen and (max-width: 640px){
        flex-direction: column;
        gap: 12px;
    }
}

.footer-item-wrapper{
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.text-1{
    font-size: 14px;
    color: #222;
    font-weight: 600;
}

.text-2, .footer-copyright{
    font-size: 14px;
    color: #666;
    font-weight: 400;
}

.footer-address{
    min-width: 27px;
}