.nav-wrapper{
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
    background-color: #fff;
    transition: all 0.22s ease-out;
    z-index: 100;
    padding: 0 16px;
}

.nav-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1120px;
}

.nav-wrapper a{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.nav-row{
    display: flex;
    flex-direction: row;
    gap: 0 36px;
}

.nav-mobile-menu-button{
    cursor: pointer;
    display: none;
    width: 44px;
    height: 44px;
    @media screen and (max-width: 1200px){
        display: flex;
    }
}

.nav-logo{
    height: 32px;
}

.nav-row a{
    color: #222;
    transition: color 0.1s ease-out;
    &:hover{
        color: #888;
    }
    @media screen and (max-width: 1200px){
        display: none;
    }
}