h1{
    font-size: 64px;
    line-height: 140%;
    color: #222;
    font-weight: 700;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 1200px){
        font-size: 36px;
    }
}

h2{
    font-size: 48px;
    line-height: 140%;
    color: #222;
    font-weight: 700;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 1200px){
        font-size: 28px;
    }
}

h3{
    font-size: 16px;
    line-height: 160%;
    color: #333;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

p{
    font-size: 14px;
    line-height: 160%;
    color: #444;
    padding: 0;
    margin: 0;
}

.w500{
    font-weight: 500;
}

.pop-transition{
    transition: all 0.33s ease-in-out;
}

.home-title-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #F2F2F7;
    padding: 0 16px;
    overflow: hidden;
}

.home-title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1120px;
    padding: 200px 0 0 0;

    @media screen and (max-width: 1200px){
        padding: 160px 0 0 0;
    }
}

.home-title-text-wrapper{
    display: flex;
    flex-direction: column;
    flex: 3 1 0;
    text-align: center; 
    align-items: center;
}

.home-title-text-button{
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: #0ECE8E;
    padding: 16px 32px 16px 32px;
    width: fit-content;
    border-radius: 28px;
    margin-top: 20px;
    margin-bottom: 36px;
    transition: all 0.22s ease-out;

    &:hover {
        background-color: #1DDB9C;
    }
}

.home-title-img-wrapper{
    position: relative;
}

.home-title-img{
    z-index: 10;
    max-width: 640px;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 1200px){
        max-width: 500px;
        width: 100%;
    }
}

.home-overview-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 16px;
    background-color: #EDEDF2;
}

.home-overview-container{
    display: flex;
    flex-direction: column;
    gap: 100px;
    width: 100%;
    padding: 120px 0;
    max-width: 1120px;

    @media screen and (max-width: 1200px){
        padding: 100px 0;
        align-content: center;
    }
}

.home-overview-title-wrapper{ 
    @media screen and (max-width: 1200px){
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.home-overview-title{
    @media screen and (max-width: 1200px){
        width: 100%;
        max-width: 640px;
    }
}

.home-overview-img{
    width: 100%;
}

.home-intro1-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 160px 16px 0 16px;
    
    @media screen and (max-width: 1200px){
        padding: 64px 16px 0 16px;
    }
}

.home-intro1-container{
    max-width: 1120px;
    width: 100%;
    
    @media screen and (max-width: 1200px){
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 64px;
    }
}

.home-intro1-section1{
    width: 100%;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1200px){
        flex-direction: column;
        max-width: 640px;
        gap: 36px;
    }
}

.home-intro1-section2{
    position: relative;
    top: -300px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        max-width: 640px;
        top: auto;
        align-items: inherit;
        gap: 36px;
    }
}

.home-intro1-section3{
    position: relative;
    top: -200px;
    width: 100%;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1200px){
        flex-direction: column;
        max-width: 640px;
        top: auto;
        gap: 36px;
    }
}

.home-intro1-text{
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    padding-top: 48px;
    
    @media screen and (max-width: 1200px){
        width: 100%;
    }
}

.home-intro1-text h2{
    margin-bottom: 64px;
    
    @media screen and (max-width: 1200px){
        margin-bottom: 24px;
    }
}

.home-intro1-img-wrapper{
    display: flex;
    @media screen and (max-width: 1200px){
        justify-content: center;
    }
}

.home-intro1-img{
    position: relative;
    right: -92px;
    display: flex;
    flex: 1 1 0;
    width: 526px;

    @media screen and (max-width: 1200px){
        max-width: 500px;
        width: 100%;
        right: auto;
    }
}

.home-intro1-img-reverse{
    position: relative;
    right: 92px;
    display: flex;
    flex: 1 1 0;
    width: 526px;

    @media screen and (max-width: 1200px){
        max-width: 500px;
        width: 100%;
        right: auto;
    }
}

.home-intro1-section2 .home-intro1-text{
    padding-left: 64px;
    padding-bottom: 172px;
    
    @media screen and (max-width: 1200px) {
        padding: 0;
    }
}

.home-intro2-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 160px 16px 0 16px ;
    background-color: #EDEDF2;

    @media screen and (max-width: 1200px){
        padding: 84px 16px 0 16px;
    }
}

.home-intro2-container{
    max-width: 1120px;
    width: 100%;
}

.home-intro2-section1{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.home-intro2-section2{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 120px 0;

    @media screen and (max-width: 1200px){
        flex-direction: column;
        align-items: center;
        gap: 36px;
        padding: 72px 0;
    }
}

.home-intro2-section1 .home-intro2-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 36px;
    margin-bottom: 48px;

    @media screen and (max-width: 1200px){
        gap: 24px;
    }
}

.home-intro2-section1 .home-intro2-img-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-intro2-img1{
    width: 100%;
    margin-bottom: 80px;
}

.home-intro2-img2{
    width: 526px;
    margin-left: 56px;
    
    @media screen and (max-width: 1200px) {
        max-width: 500px;
        width: 100%;
        margin: 0;
    }
}

.home-intro2-section2 .home-intro2-img-wrapper{
    position: relative;
    left: 100px;

    @media screen and (max-width: 1200px){
        position: inherit;
        left: auto;
        display: flex;
        justify-content: center;
    }
}

.home-intro2-img3{
    width: 680px;
    
    @media screen and (max-width: 1200px){
        width: 100%;
        max-width: 640px;
    }
}

.home-intro2-section2 .home-intro2-text{
    margin-top: 48px;
    
    @media screen and (max-width: 1200px){
        width: 100%;
        max-width: 640px;
        margin-top: 0;
    }
}

.home-intro2-section2 h2{
    margin-bottom: 48px;

    @media screen and (max-width: 1200px){
        margin-bottom: 24px;
    }
}

.home-recommend-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 100px 16px;

    @media screen and (max-width: 480px){
        padding: 72px 16px;
    }
}

.home-recommend-container{
    width: 100%;
    max-width: 1120px;
    display: flex;
    flex-direction: column;
}

.home-recommend-container h2{
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        margin-bottom: 36px;
    }
}

.home-recommend-img-wrapper{
    height: fit-content;
}

.home-recommend-img{
    width: 100%;
}

.home-recommend-text-wrapper{
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 640px){
        flex-direction: column;
    }
}

.home-recommend-text-container-left, .home-recommend-text-container-right{
    display: flex;
    flex-direction: column;
    gap: 64px;
    border-left: 1px solid rgba(0,0,0,.1);
    padding-left: 36px;
    padding-top: 64px;
    flex: 1 1 0;

    @media screen and (max-width: 640px){
        padding: 0;
        gap: 48px;
        border: none;
        margin-top: 48px;
    }
}

.home-recommend-text h3{
    margin-bottom: 16px;

    @media screen and (max-width: 640px){
        font-weight: 700;
    }
}

.home-recommend-text{
    width: 360px;

    @media screen and (max-width: 1200px){
        width: 240px;
    }

    @media screen and (max-width: 640px){
        width: 100%;
    }
}

.home-strongpoint-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 16px;
    background-color: #EDEDF2;

    @media screen and (max-width: 1200px){
        padding: 64px 16px;
    }
}

.home-strongpoint-container{
    max-width: 1120px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.home-strongpoint-container h2{
    margin-bottom: 100px;

    @media screen and (max-width: 1200px){
        margin-bottom: 48px;
    }
}

.home-strongpoint-text-wrapper{
    display: flex;
    flex-direction: row;
    gap: 48px;

    @media screen and (max-width: 640px){
        flex-direction: column;
        gap: 36px;
    }
}

.home-strongpoint-text-section{
    flex: 1 1 0;
}

.home-strongpoint-text-title{
    padding-bottom: 20px;
    border-bottom: 1px solid #222;

    @media screen and (max-width: 640px){
        padding-bottom: 12px;
    }

    h3{ 
        @media screen and (max-width: 640px){
            font-weight: 700;
        }
    }
}

.home-strongpoint-text-script p:nth-child(1){
    margin: 24px 0 12px 0;

    @media screen and (max-width: 640px){
        margin: 12px 0;
    }
}

.home-strongpoint-text-script p{
    margin: 12px 0;
}